.header {
  width: 100%;
  height: 10vh;
  background-color: #101010;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  width: 93%;
  height: 100%;
  background-color: #101010;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  color: #ffffff;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  padding-left: 50px;
}
