.cards {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding-top: 30px;
}
.image {
  width: 25%;
  height: 100%;
  object-fit: contain;
  background-image: initial;
  padding: 15px;
}
img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.dataFromApi {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #cfcfcf;
}
.locationInIndia {
  width: auto;
  height: 20%;
  display: flex;
  flex-direction: row;
  background-color: black;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
  padding: 0px 20px;
  margin: 0px 5px;
  cursor: pointer;
}
